import React, { Fragment, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import { RemoveIcon } from 'assets/img';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import { priceFormatter } from 'utils/helpers/validation';
import { observer } from 'mobx-react';
import EmbedHTML from 'components/Shared/EmbedHTML';
import ConfirmationModal from 'components/ConfirmationModal';
import { Disclosure } from '@headlessui/react';
import classNames from 'classnames';
import { useMenuItemSelection } from 'utils/hooks/useMenuItemSelection';
import CloseButton from 'components/CloseButton';
import SubmitActions from 'components/AddItemModal/SubmitActions';
import { useRouter } from 'next/router';

function AddGroupItemModal({
  visible,
  setModal,
  selectedItem,
  openedItems,
  addGroupOrderItemToCart,
  updateItem,
  edit,
  setImage,
  editItem,
  attendeeToUpdate,
  onClickRemoveAttendee,
  showEditSection = false,
  setModalKey,
  modalKey,
}) {
  const screenType = Screen.ScreenType();
  const [attachLabelAlert, setAttachLabelAlert] = useState(null);
  const [isMultipleItemArray, setIsMultipleItemArray] = useState(editItem && editItem.length > 1);

  const calculateInitialPrice = () => {
    if (edit) {
      if (isMultipleItemArray) {
        return editItem.map(item => ({
          [item.attributes.menu_item.id]: item.attributes.total_price,
        }));
      } else {
        const { menu_item, total_price } = editItem?.attributes;
        return { [menu_item?.id]: total_price };
      }
    } else return openedItems?.[selectedItem]?.attributes.price;
  };

  const [getItemTotalPrice, setItemTotalPrice] = useState(calculateInitialPrice());

  const [optionTypesPrice, setOptionTypesPrice] = useState(edit ? {} : 0);

  const store = useContext(StoreContext);
  const groupOrderStore = toJS(store?.groupOrderStore);
  let { setSelectedOptions, initRequiredCheck, updateQuantity } = useMenuItemSelection();
  const menuItem = edit ? editItem?.attributes?.menu_item : openedItems?.[selectedItem]?.attributes;
  const router = useRouter();
  const isAdmin = router?.query?.admin;

  const [fullName, setFullName] = useState(
    attendeeToUpdate?.first_name && attendeeToUpdate?.last_name
      ? `${attendeeToUpdate?.first_name} ${attendeeToUpdate?.last_name}`
      : attendeeToUpdate?.first_name || attendeeToUpdate?.last_name || ''
  );

  const [foodTotalPrice, setFoodTotalPrice] = useState(attendeeToUpdate?.food_total);

  function createInitialProvider() {
    return {
      isInTime: true,
      optionTypes: {},
      instructions: '',
      nameLabel: '',
      quantity: 1,
      addItem: {
        options: [],
        instructions: '',
      },
    };
  }

  let initialProvider = isMultipleItemArray
    ? editItem.map(() => createInitialProvider())
    : createInitialProvider();

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return initialProvider;
      default:
        if (isMultipleItemArray) {
          return state.map((provider, index) =>
            index == action.index ? { ...provider, [action.field]: action.value } : provider
          );
        } else {
          return {
            ...state,
            [action.field]: action.value,
          };
        }
    }
  }

  const [provider, dispatch] = useReducer(reducer, initialProvider);

  let { nameLabel } = provider;

  let { attendeeId, orderDetails } = groupOrderStore;

  useEffect(() => {
    dispatch({ type: 'reset' });

    if (!edit) initRequiredCheck(menuItem, dispatch);
    else {
      isMultipleItemArray
        ? editItem.forEach((item, index) => {
            setSelectedOptions(item, 'group_order_item_options', dispatch, index);
          })
        : setSelectedOptions(editItem, 'group_order_item_options', dispatch);
    }
  }, [editItem]);

  const getOptionTypeIDs = provider => {
    const options = provider?.optionTypes ? Object.values(provider?.optionTypes).flat() : [];
    dispatch({
      field: 'addItem',
      value: {
        ...provider?.addItem,
        options: options,
      },
    });
  };

  useEffect(() => {
    if (edit) setModalKey(prev => prev + 1);
  }, [foodTotalPrice, getItemTotalPrice, menuItem, provider]);

  const updateTotalPrices = (menuItem, provider, updatedOptionTypesPrice) => {
    let selectedOptionsSum = 0;
    let updatedTotalPrice = { ...getItemTotalPrice };

    selectedOptionsSum = Number(menuItem?.price);

    let totalItemPrice = parseFloat(
      parseFloat(edit ? updatedOptionTypesPrice[menuItem?.id] : updatedOptionTypesPrice) +
        (selectedOptionsSum || parseFloat(menuItem?.price))
    ).toFixed(2);

    if (edit && isMultipleItemArray) {
      for (let key in updatedTotalPrice) {
        if (menuItem?.id in updatedTotalPrice[key]) {
          updatedTotalPrice[key][menuItem?.id] =
            totalItemPrice * (provider?.quantity == '' ? 1 : provider?.quantity);
        }
      }
      setItemTotalPrice(updatedTotalPrice);
    } else if (edit && !isMultipleItemArray) {
      updatedTotalPrice[menuItem?.id] =
        totalItemPrice * (provider?.quantity == '' ? 1 : provider?.quantity);
      setItemTotalPrice(updatedTotalPrice);
    } else {
      setItemTotalPrice(totalItemPrice * (provider?.quantity == '' ? 1 : provider?.quantity));
    }

    let total = 0;
    Object.values(updatedTotalPrice).forEach(item => {
      Object.values(item).forEach(value => {
        total += parseFloat(value);
      });
    });

    setFoodTotalPrice(isMultipleItemArray ? total : updatedTotalPrice[menuItem?.id]);
  };

  const updatePrices = (menuItem, provider) => {
    const options = menuItem?.option_categories;
    getOptionTypeIDs(provider);
    const selectedOptionsValues = provider?.optionTypes
      ? Object.values(provider?.optionTypes).flat()
      : [];

    const updatedOptionTypesPrice = { ...optionTypesPrice };
    let price = 0;
    options?.forEach((e, index) => {
      e.attributes.options.forEach(option => {
        if (selectedOptionsValues.includes(option.id)) {
          price += parseFloat(option.attributes.price);
        }
      });
    });

    if (edit) {
      if (updatedOptionTypesPrice?.hasOwnProperty(menuItem?.id)) {
        updatedOptionTypesPrice[menuItem?.id] = price;
      } else {
        updatedOptionTypesPrice[menuItem?.id] = price;
      }
      setOptionTypesPrice(updatedOptionTypesPrice);
    } else {
      setOptionTypesPrice(price);
    }

    updateTotalPrices(menuItem, provider, edit ? updatedOptionTypesPrice : price);
  };

  const radioCheck = (id, optionTypeId, type, required, provider, index, menuItem) => {
    let options = provider?.optionTypes ? Object.values(provider?.optionTypes)?.flat() : [];

    if (Object?.values(options)?.includes(id) && !required) {
      dispatch({
        field: 'optionTypes',
        value: Object.assign({}, provider?.optionTypes, {
          [optionTypeId]: [],
        }),
        index: index,
      });
    } else {
      dispatch({
        field: 'optionTypes',
        value: Object.assign({}, provider?.optionTypes, {
          [optionTypeId]: id,
        }),
        index: index,
      });
    }
  };

  const onCheckboxChange = (id, optionType, optionTypesArr, event, provider, index) => {
    //prevent checkbox to uncheck if its required
    if (
      optionType?.attributes?.required &&
      optionTypesArr?.length === 1 &&
      !event?.target?.checked
    ) {
      event.preventDefault();
      return;
    }

    dispatch({
      field: 'optionTypes',
      value: Object.assign({}, provider?.optionTypes, {
        [optionType.id]: event.target.checked
          ? optionTypesArr?.length > 0
            ? optionTypesArr?.concat(id)
            : [id]
          : optionTypesArr?.filter(option => option !== id),
      }),
      index: index,
    });
  };

  const addToCart = () => {
    if (showEditSection) {
      const [updatedFirstName, ...updatedLastNameArray] = fullName.split(' ');
      const updatedLastName = updatedLastNameArray.join(' ');

      let payload = {
        attendee: {
          first_name: updatedFirstName || '',
          last_name: updatedLastName || '',
        },
      };

      store.userStore.setLoader(true);
      store.groupOrderStore.updateAttendee(attendeeToUpdate.id, payload).then(response => {
        store.userStore.setLoader(false);
      });
    }

    if (edit && isMultipleItemArray) {
      provider.forEach((providerItem, index) => {
        let selectedOptions = [];

        Object.keys(providerItem?.optionTypes).forEach(function (key) {
          let isArray = Array.isArray(providerItem?.optionTypes[key]);
          selectedOptions = [
            ...selectedOptions,
            isArray
              ? providerItem?.optionTypes[key]?.map(option_id => {
                  return { option_id, option_group_id: key };
                })
              : { option_id: providerItem?.optionTypes[key], option_group_id: key },
          ];
        });

        const groupOrderItem = {
          menu_item_id: editItem[index].attributes?.menu_item?.id,
          quantity: providerItem?.quantity,
          special_instructions: providerItem?.instructions,
          menu_category_id: editItem[index].attributes?.menu_category_id,
          group_order_item_options_attributes: selectedOptions.flat(),
        };

        let groupOrderPayload = {
          group_order_item: groupOrderItem,
        };

        updateItem(editItem[index]?.id, groupOrderPayload);
      });
    } else {
      let selectedOptions = [];
      Object.keys(provider?.optionTypes).forEach(function (key) {
        let isArray = Array.isArray(provider?.optionTypes[key]);
        selectedOptions = [
          ...selectedOptions,
          isArray
            ? provider?.optionTypes[key]?.map(option_id => {
                return { option_id, option_group_id: key };
              })
            : { option_id: provider?.optionTypes[key], option_group_id: key },
        ];
      });

      const groupOrderItem = {
        menu_item_id: menuItem?.id,
        quantity: provider?.quantity,
        special_instructions: provider?.instructions,
        menu_category_id: edit
          ? editItem?.attributes?.menu_category_id
          : openedItems?.[selectedItem]?.menuCategoryId,
        group_order_item_options_attributes: selectedOptions.flat(),
      };

      let groupOrderPayload = {
        group_order_item: groupOrderItem,
      };

      if (edit) updateItem(editItem?.id, groupOrderPayload);
      else {
        if (orderDetails?.attributes?.slug) {
          if (isAdmin) {
            const payload = {
              admin: true,
              slug: orderDetails?.attributes?.slug,
              attendee: {
                first_name: nameLabel ? nameLabel : '',
                group_order_items_attributes: [groupOrderItem],
              },
            };

            store.userStore.setLoader(true);
            store.groupOrderStore.createAttendee(payload).then(response => {
              let attendeeIdFromResponse = response?.attendee_id;

              if (orderDetails && attendeeIdFromResponse) {
                store?.groupOrderStore
                  ?.getAllGroupOrderCartItems({
                    slug: orderDetails?.attributes?.slug,
                    admin: true,
                  })
                  .then(() => {
                    store.userStore.setLoader(false);
                    setModal(false);
                  });
              }
            });
          } else {
            if (attendeeId) {
              groupOrderPayload.attendee_id = attendeeId;
              groupOrderPayload.slug = orderDetails?.attributes?.slug;
              addGroupOrderItemToCart(groupOrderPayload, attendeeId);
            } else {
              store.userStore.setLoader(true);
              store.groupOrderStore
                .createAttendee({ slug: orderDetails?.attributes?.slug })
                .then(response => {
                  let attendeeIdFromResponse = response?.attendee_id;

                  if (attendeeIdFromResponse) {
                    groupOrderPayload.attendee_id = attendeeIdFromResponse;
                    groupOrderPayload.slug = orderDetails?.attributes?.slug;

                    addGroupOrderItemToCart(groupOrderPayload, attendeeIdFromResponse);
                  }
                })
                .catch(() => {
                  store.userStore.setLoader(false);
                });
            }
          }
        }
      }
    }

    setTimeout(() => {
      dispatch({ type: 'reset' });
      initRequiredCheck(menuItem, dispatch);
    }, 1000);
  };

  const onReduceQuantity = () => {
    dispatch({
      field: 'quantity',
      value: 1,
    });

    setAttachLabelAlert(null);
  };

  const submitActions = (provider, index, menuItem) => (
    <SubmitActions
      edit={edit}
      provider={provider}
      dispatch={dispatch}
      setModal={setModal}
      getItemTotalPrice={
        isMultipleItemArray
          ? getItemTotalPrice?.[index][menuItem?.id]
          : edit
          ? getItemTotalPrice[menuItem?.id]
          : getItemTotalPrice
      }
      updateQuantity={updateQuantity}
      setAttachLabelAlert={setAttachLabelAlert}
      addToCart={addToCart}
      isGroupOrder={true}
      isAdmin={isAdmin}
      menuItem={menuItem}
      providerIndex={index}
      updatePrices={updatePrices}
      showEditSection={showEditSection}
    />
  );

  const isDisabled = (option, provider, optionType, index) => {
    if (index) {
      return (
        option.attributes?.availability !== 'active' ||
        (provider?.[index]?.optionTypes?.[optionType?.id]?.length >=
          optionType?.attributes?.choose_upto &&
          provider?.[index]?.optionTypes?.[optionType?.id]?.indexOf(option.id) === -1)
      );
    } else {
      return (
        option.attributes?.availability !== 'active' ||
        (provider?.optionTypes?.[optionType?.id]?.length >= optionType?.attributes?.choose_upto &&
          provider?.optionTypes?.[optionType?.id]?.indexOf(option.id) === -1)
      );
    }
  };

  function stripHtmlTags(html) {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  useEffect(() => {
    if (edit && isMultipleItemArray) {
      editItem?.forEach((item, index) => {
        const menuItem = item?.attributes?.menu_item;
        updatePrices(menuItem, provider?.[index]);
      });
    } else {
      updatePrices(menuItem, provider);
    }
  }, [editItem, provider]);

  const children = (
    <>
      <Transition.Child
        // as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>
      <div className="fixed top-0 right-0 left-0 sm:inset-0">
        <div className="flex min-h-screen max-h-screen md:min-h-full md:items-center md:justify-center md:p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Dialog.Panel className="flex flex-col max-h-screen overflow-auto flex-1 pb-44 md:pb-10 md:flex-initial w-full md:w-5/12 transform md:rounded-2xl bg-white py-10 px-8 text-left align-middle shadow-xl transition-all">
              <div className="hidden sm:flex sticky justify-between items-start mb-3">
                <div className="flex w-full">
                  <div className="flex items-stretch w-full">
                    {menuItem?.image_url && !showEditSection && (
                      <div
                        className="flex rounded-xl overflow-hidden"
                        onClick={() => setImage(menuItem?.image_url)}>
                        <Image
                          src={menuItem?.image_url}
                          width={161}
                          height={188}
                          layout="fixed"
                          objectFit="cover"
                          alt="menu-item-image"
                        />
                      </div>
                    )}

                    <div
                      className={classNames({
                        'flex max-w-[60%] flex-col': true,
                        'ml-6': menuItem?.image_url,
                      })}>
                      <Dialog.Title
                        as="h3"
                        style={{
                          fontSize: screenType?.isTabletOrMobile ? 23 : 32,
                        }}
                        className="font-inter-semibold leading-loose text-black-light">
                        <EmbedHTML
                          text={
                            showEditSection
                              ? `${fullName} Order`
                              : stripHtmlTags(menuItem?.display_name_with_html)
                          }
                          className={'text-black-light leading-tight'}
                        />
                      </Dialog.Title>

                      {!showEditSection && (
                        <span className="text-md mt-3">
                          <EmbedHTML
                            text={stripHtmlTags(menuItem?.description_with_html)}
                            className={'text-black-light'}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <CloseButton setModal={setModal} />
              </div>

              <div className="flex sm:hidden sticky justify-between items-center mb-3">
                <Dialog.Title
                  as="h3"
                  style={{ fontSize: screenType?.isTabletOrMobile ? 23 : 32 }}
                  className="font-inter-semibold leading-6 text-black-light">
                  <EmbedHTML
                    text={stripHtmlTags(menuItem?.display_name_with_html)}
                    className={'text-black-light leading-9'}
                  />
                </Dialog.Title>

                <CloseButton setModal={setModal} />
              </div>

              <span className="text-md sm:hidden">
                <EmbedHTML
                  text={showEditSection ? 'Items' : stripHtmlTags(menuItem?.description_with_html)}
                  className={'text-black-light'}
                />
              </span>

              {edit && showEditSection && (
                <div className="flex flex-col">
                  <div className="flex justify-between items-center py-2">
                    <span className="text-slate-700 font-inter-normal leading-tight">
                      Meal labelled as:
                    </span>
                  </div>

                  <div>
                    <input
                      className="input-light text-md focus:outline-none focus:ring-0 rounded-xl"
                      onChange={e => setFullName(e.target.value)}
                      value={fullName}
                    />
                  </div>

                  <div className="text-2xl font-inter-semibold text-neutral-700 mb-3 mt-5">
                    <EmbedHTML text="Items" className={'text-black-light'} />
                  </div>
                </div>
              )}

              <div className={`overflow-y-scroll flex flex-col gap-3`}>
                {edit && isMultipleItemArray ? (
                  <>
                    {editItem.map((item, index) => {
                      const menuItem = item?.attributes?.menu_item;

                      return (
                        <React.Fragment>
                          <div className={` ${edit ? 'bg-slate-50' : 'bg-white'} p-3`}>
                            <div className="text-neutral-700 text-lg font-inter-semibold leading-tight mb-3">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: menuItem.display_name_with_html,
                                }}
                              />
                            </div>

                            <div className="flex sm:hidden">
                              {menuItem?.image_url && (
                                <div
                                  className="flex rounded-xl overflow-hidden mt-5"
                                  style={{ width: '100%', minHeight: 192, height: 192 }}
                                  onClick={() => setImage(menuItem?.image_url)}>
                                  <Image
                                    src={menuItem?.image_url}
                                    width={1500}
                                    height={192}
                                    objectFit="cover"
                                    alt="menu-item-image"
                                  />
                                </div>
                              )}
                            </div>

                            <div className="flex flex-col flex-wrap">
                              {menuItem?.option_categories?.map(optionType => {
                                return (
                                  <React.Fragment>
                                    {optionType?.attributes?.choose_upto === 0 && (
                                      <div key={optionType.id} className="flex flex-col mb-7">
                                        <span className="uppercase text-md mb-1">
                                          <EmbedHTML
                                            isRow
                                            className="uppercase text-md mb-1"
                                            text={stripHtmlTags(
                                              optionType?.attributes.display_name_with_html
                                            )}
                                            append={`(Unlimited) ${
                                              optionType.attributes.required ? '*' : ''
                                            }`}
                                            appendClass="ml-1"
                                          />
                                        </span>

                                        <div className="flex flex-col flex-wrap">
                                          {optionType?.attributes?.options?.map(option => (
                                            <label
                                              key={option.id}
                                              className="inline-flex items-center py-2 text-sm select-none mr-4">
                                              <input
                                                autoComplete="off"
                                                type="checkbox"
                                                className="form-checkbox"
                                                disabled={
                                                  option.attributes?.availability !== 'active'
                                                }
                                                value={option.id}
                                                checked={provider?.[index]?.optionTypes[
                                                  optionType.id
                                                ]?.includes(option.id)}
                                                onChange={e => {
                                                  onCheckboxChange(
                                                    option.id,
                                                    optionType,
                                                    provider?.[index]?.optionTypes[optionType.id],
                                                    e,
                                                    provider?.[index],
                                                    index
                                                  );
                                                  updatePrices(menuItem, provider?.[index]);
                                                }}
                                              />

                                              <span className="ml-1 font-inter-regular text-md capitalize">
                                                <EmbedHTML
                                                  isRow
                                                  className="text-primary-black"
                                                  text={stripHtmlTags(
                                                    option.attributes.display_name_with_html
                                                  )}
                                                  append={
                                                    option?.attributes?.availability === 'sold_out'
                                                      ? 'Sold Out'
                                                      : `+${priceFormatter(
                                                          option.attributes.price
                                                        )}`
                                                  }
                                                  appendClass={`${
                                                    edit
                                                      ? 'text-zinc-200 font-inter-normal'
                                                      : 'text-primary-black'
                                                  } text-md ml-1`}
                                                />
                                              </span>
                                            </label>
                                          ))}
                                        </div>
                                      </div>
                                    )}

                                    {optionType?.attributes?.choose_upto >= 2 && (
                                      <div key={optionType.id} className="flex flex-col mb-7">
                                        <EmbedHTML
                                          isRow
                                          className="uppercase text-md mb-1"
                                          text={stripHtmlTags(
                                            optionType?.attributes.display_name_with_html
                                          )}
                                          append={`(choose up to ${
                                            optionType.attributes.choose_upto
                                          }${optionType.attributes.required ? '*' : ''})`}
                                          appendClass="text-xs ml-1"
                                        />

                                        <div className="flex flex-col flex-wrap">
                                          {optionType?.attributes?.options?.map(option => (
                                            <label
                                              key={option.id}
                                              className="inline-flex items-center py-2 text-sm select-none mr-4">
                                              <input
                                                autoComplete="off"
                                                type="checkbox"
                                                className="form-checkbox"
                                                value={option.id}
                                                checked={provider?.[index]?.optionTypes[
                                                  optionType.id
                                                ]?.includes(option.id)}
                                                disabled={isDisabled(
                                                  option,
                                                  provider,
                                                  optionType,
                                                  index
                                                )}
                                                onChange={e => {
                                                  onCheckboxChange(
                                                    option.id,
                                                    optionType,
                                                    provider?.[index]?.optionTypes[optionType.id],
                                                    e,
                                                    provider?.[index],
                                                    index
                                                  );
                                                  updatePrices(menuItem, provider?.[index]);
                                                }}
                                              />

                                              <div className="ml-1 font-inter-regular text-md capitalize">
                                                <EmbedHTML
                                                  isRow
                                                  className="text-primary-black"
                                                  text={stripHtmlTags(
                                                    option.attributes.display_name_with_html
                                                  )}
                                                  append={
                                                    option?.attributes?.availability === 'sold_out'
                                                      ? 'Sold Out'
                                                      : `+${priceFormatter(
                                                          option.attributes.price
                                                        )}`
                                                  }
                                                  appendClass={`${
                                                    edit
                                                      ? 'text-zinc-200 font-inter-normal'
                                                      : 'text-primary-black'
                                                  } text-md ml-1`}
                                                />
                                              </div>
                                            </label>
                                          ))}
                                        </div>
                                      </div>
                                    )}

                                    {optionType?.attributes?.choose_upto === 1 && (
                                      <div key={optionType.id} className="flex flex-col mb-7">
                                        <span className="uppercase text-md mb-1">
                                          <EmbedHTML
                                            isRow
                                            className="uppercase text-md mb-1"
                                            text={stripHtmlTags(
                                              optionType?.attributes.display_name_with_html
                                            )}
                                            append={`${optionType.attributes.required ? '*' : ''}`}
                                            appendClass="ml-1"
                                          />
                                        </span>

                                        <div className="flex flex-col flex-wrap">
                                          {optionType?.attributes?.options?.map(option => (
                                            <label
                                              key={option.id}
                                              className="inline-flex items-center py-2 text-sm select-none mr-4">
                                              <input
                                                autoComplete="off"
                                                type="radio"
                                                className="form-checkbox"
                                                value={option.id}
                                                name={optionType.attributes.name}
                                                checked={provider?.[index]?.optionTypes[
                                                  optionType.id
                                                ]?.includes(option.id)}
                                                disabled={
                                                  option.attributes?.availability !== 'active'
                                                }
                                                onClick={e => {
                                                  radioCheck(
                                                    option.id,
                                                    optionType.id,
                                                    optionType.attributes.name,
                                                    optionType.attributes.required,
                                                    provider?.[index],
                                                    index
                                                  );
                                                  updatePrices(menuItem, provider?.[index]);
                                                }}
                                              />
                                              <span className="ml-1 font-inter-regular text-md capitalize">
                                                <EmbedHTML
                                                  isRow
                                                  className="text-primary-black"
                                                  text={stripHtmlTags(
                                                    option.attributes.display_name_with_html
                                                  )}
                                                  append={
                                                    option?.attributes?.availability === 'sold_out'
                                                      ? 'Sold Out'
                                                      : `+${priceFormatter(
                                                          option.attributes.price
                                                        )}`
                                                  }
                                                  appendClass={`${
                                                    edit
                                                      ? 'text-zinc-200 font-inter-normal'
                                                      : 'text-primary-black'
                                                  } text-md ml-1`}
                                                />
                                              </span>
                                            </label>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </div>

                            <div className="flex flex-col">
                              <div className="flex justify-between items-center uppercase py-[11px]">
                                <span className="text-md font-inter-light">
                                  Special Instructions
                                </span>
                              </div>

                              <div>
                                <input
                                  className="input-light text-md focus:outline-none focus:ring-0"
                                  placeholder="Ex. do not add tomatoes"
                                  value={provider?.[index].instructions}
                                  onChange={e =>
                                    dispatch({
                                      field: 'instructions',
                                      value: e.target.value,
                                      index: index,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className="w-full">
                              {submitActions(provider?.[index], index, menuItem)}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <React.Fragment>
                    <div className={` ${showEditSection ? 'bg-slate-50' : 'bg-white'} p-3`}>
                      <div class="text-neutral-700 text-lg font-inter-semibold leading-tight mb-3">
                        {stripHtmlTags(menuItem?.display_name_with_html)}
                      </div>

                      <div className="flex sm:hidden">
                        {menuItem?.image_url && (
                          <div
                            className="flex rounded-xl overflow-hidden mt-5"
                            style={{ width: '100%', minHeight: 192, height: 192 }}
                            onClick={() => setImage(menuItem?.image_url)}>
                            <Image
                              src={menuItem?.image_url}
                              width={1500}
                              height={192}
                              objectFit="cover"
                              alt="menu-item-image"
                            />
                          </div>
                        )}
                      </div>

                      <div className="flex flex-col flex-wrap">
                        {menuItem?.option_categories?.map(optionType => (
                          <>
                            {optionType?.attributes?.choose_upto === 0 && (
                              <div key={optionType.id} className="flex flex-col mb-7">
                                <span className="uppercase text-md mb-1">
                                  <EmbedHTML
                                    isRow
                                    className="uppercase text-md mb-1"
                                    text={stripHtmlTags(
                                      optionType?.attributes.display_name_with_html
                                    )}
                                    append={`(Unlimited) ${
                                      optionType.attributes.required ? '*' : ''
                                    }`}
                                    appendClass="ml-1"
                                  />
                                </span>

                                <div className="flex flex-col flex-wrap">
                                  {optionType?.attributes?.options?.map(option => (
                                    <label
                                      key={option.id}
                                      className="inline-flex items-center py-2 text-sm select-none mr-4">
                                      <input
                                        autoComplete="off"
                                        type="checkbox"
                                        className="form-checkbox"
                                        disabled={option.attributes?.availability !== 'active'}
                                        value={option.id}
                                        checked={provider?.optionTypes[optionType.id]?.includes(
                                          option.id
                                        )}
                                        onChange={e => {
                                          onCheckboxChange(
                                            option.id,
                                            optionType,
                                            provider?.optionTypes[optionType.id],
                                            e,
                                            provider
                                          );
                                          updatePrices(menuItem, provider);
                                        }}
                                      />

                                      <span className="ml-1 font-inter-regular text-md capitalize">
                                        <EmbedHTML
                                          isRow
                                          className="text-primary-black"
                                          text={stripHtmlTags(
                                            option.attributes.display_name_with_html
                                          )}
                                          append={
                                            option?.attributes?.availability === 'sold_out'
                                              ? 'Sold Out'
                                              : `+${priceFormatter(option.attributes.price)}`
                                          }
                                          appendClass={`${
                                            edit
                                              ? 'text-zinc-200 font-inter-normal'
                                              : 'text-primary-black'
                                          } text-md ml-1`}
                                        />
                                      </span>
                                    </label>
                                  ))}
                                </div>
                              </div>
                            )}

                            {optionType?.attributes?.choose_upto >= 2 && (
                              <div key={optionType.id} className="flex flex-col mb-7">
                                <EmbedHTML
                                  isRow
                                  className="uppercase text-md mb-1"
                                  text={stripHtmlTags(
                                    optionType?.attributes.display_name_with_html
                                  )}
                                  append={`(choose up to ${optionType.attributes.choose_upto}${
                                    optionType.attributes.required ? '*' : ''
                                  })`}
                                  appendClass="text-xs ml-1"
                                />

                                <div className="flex flex-col flex-wrap">
                                  {optionType?.attributes?.options?.map(option => (
                                    <label
                                      key={option.id}
                                      className="inline-flex items-center py-2 text-sm select-none mr-4">
                                      <input
                                        autoComplete="off"
                                        type="checkbox"
                                        className="form-checkbox"
                                        value={option.id}
                                        checked={provider?.optionTypes[optionType.id]?.includes(
                                          option.id
                                        )}
                                        disabled={isDisabled(option, provider, optionType)}
                                        onChange={e => {
                                          onCheckboxChange(
                                            option.id,
                                            optionType,
                                            provider?.optionTypes[optionType.id],
                                            e,
                                            provider
                                          );
                                          updatePrices(menuItem, provider);
                                        }}
                                      />

                                      <div className="ml-1 font-inter-regular text-md capitalize">
                                        <EmbedHTML
                                          isRow
                                          className="text-primary-black"
                                          text={stripHtmlTags(
                                            option.attributes.display_name_with_html
                                          )}
                                          append={
                                            option?.attributes?.availability === 'sold_out'
                                              ? 'Sold Out'
                                              : `+${priceFormatter(option.attributes.price)}`
                                          }
                                          appendClass={`${
                                            edit
                                              ? 'text-zinc-200 font-inter-normal'
                                              : 'text-primary-black'
                                          } text-md ml-1`}
                                        />
                                      </div>
                                    </label>
                                  ))}
                                </div>
                              </div>
                            )}

                            {optionType?.attributes?.choose_upto === 1 && (
                              <div key={optionType.id} className="flex flex-col mb-7">
                                <span className="uppercase text-md mb-1">
                                  <EmbedHTML
                                    isRow
                                    className="uppercase text-md mb-1"
                                    text={stripHtmlTags(
                                      optionType?.attributes.display_name_with_html
                                    )}
                                    append={`${optionType.attributes.required ? '*' : ''}`}
                                    appendClass="ml-1"
                                  />
                                </span>

                                <div className="flex flex-col flex-wrap">
                                  {optionType?.attributes?.options?.map(option => (
                                    <label
                                      key={option.id}
                                      className="inline-flex items-center py-2 text-sm select-none mr-4">
                                      <input
                                        autoComplete="off"
                                        type="radio"
                                        className="form-checkbox"
                                        value={option.id}
                                        name={optionType.attributes.name}
                                        checked={provider?.optionTypes[optionType.id] == option.id}
                                        disabled={option.attributes?.availability !== 'active'}
                                        onClick={e => {
                                          radioCheck(
                                            option.id,
                                            optionType.id,
                                            optionType.attributes.name,
                                            optionType.attributes.required,
                                            provider
                                          );
                                          updatePrices(menuItem, provider);
                                        }}
                                      />
                                      <span className="ml-1 font-inter-regular text-md capitalize">
                                        <EmbedHTML
                                          isRow
                                          className="text-primary-black"
                                          text={stripHtmlTags(
                                            option.attributes.display_name_with_html
                                          )}
                                          append={
                                            option?.attributes?.availability === 'sold_out'
                                              ? 'Sold Out'
                                              : `+${priceFormatter(option.attributes.price)}`
                                          }
                                          appendClass={`${
                                            edit
                                              ? 'text-zinc-200 font-inter-normal'
                                              : 'text-primary-black'
                                          } text-md ml-1`}
                                        />
                                      </span>
                                    </label>
                                  ))}
                                </div>
                              </div>
                            )}
                          </>
                        ))}

                        <div className="flex flex-col">
                          <div className="flex justify-between items-center uppercase py-[11px]">
                            <span className="text-md font-inter-light">Special Instructions</span>
                          </div>

                          <div>
                            <input
                              className="input-light text-md focus:outline-none focus:ring-0"
                              placeholder="Ex. do not add tomatoes"
                              onChange={e =>
                                dispatch({
                                  field: 'instructions',
                                  value: e.target.value,
                                })
                              }
                              value={provider?.instructions}
                            />
                          </div>
                        </div>

                        {isAdmin && !edit && (
                          <div className="flex flex-col mt-2">
                            <div className="flex justify-between items-center uppercase py-[11px]">
                              <span className="text-md font-inter-light">
                                Attach a name to this item
                              </span>
                            </div>

                            <div>
                              <input
                                className="input-light text-md focus:outline-none focus:ring-0"
                                placeholder="Ex. Jane Doe"
                                onChange={e => {
                                  if (provider.quantity > 1) setAttachLabelAlert(true);
                                  else
                                    dispatch({
                                      field: 'nameLabel',
                                      value: e.target.value,
                                    });
                                }}
                                value={provider?.nameLabel}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="w-full">{submitActions(provider, '', menuItem)}</div>
                    </div>
                  </React.Fragment>
                )}
              </div>

              {showEditSection && (
                <React.Fragment>
                  <div className="flex flex-col gap-3 mt-4">
                    {edit && (
                      <div className="flex-col bg-white">
                        <div className="w-full px-3 py-6 bg-slate-50 rounded-xl flex justify-between">
                          <div className="text-slate-700 text-sm font-inter-normal">Food Total</div>

                          <div className="text-gray-800 text-base font-inter-semibold leading-tight">
                            {priceFormatter(foodTotalPrice)}
                          </div>
                        </div>

                        <button
                          className="w-full h-12 p-2.5 bg-slate-50 rounded-xl mt-6 text-red-400 text-sm font-inter-medium capitalize flex justify-center items-center"
                          onClick={() => onClickRemoveAttendee(attendeeToUpdate)}>
                          Delete {fullName}'s Meal
                        </button>

                        <div className="mt-3 flex justify-center items-center gap-3">
                          <button
                            className="w-[98px] h-12 p-2.5 rounded-md border border-zinc-200 text-slate-700 font-inter-medium capitalize"
                            onClick={() => setModal(false)}>
                            Cancel
                          </button>
                          <button
                            className="w-[164px] h-12 p-2.5 bg-indigo-900 rounded-md border border-indigo-900 text-white fornt-inter-medium capitalize"
                            onClick={() => addToCart()}>
                            Save changes
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>

      <Transition.Child
        as={'div'}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="md:hidden">
          <div className="fixed bg-white w-full p-5 bottom-0 z-[90] justify-center items-center border-t-2 border-gray-200">
            {submitActions}
          </div>
        </div>
      </Transition.Child>

      {attachLabelAlert && (
        <ConfirmationModal
          visible={attachLabelAlert}
          setModal={setAttachLabelAlert}
          title={'Assign a name to item'}
          body={`To individualize this item and attach a name to it - the total number of ${menuItem?.title} items in your cart will be reduced to 1. Add as many items with names individually, or unnamed items in bulk to your cart.`}
          rightButtonText={'Reduce To 1'}
          onClickRightButton={onReduceQuantity}
        />
      )}
    </>
  );

  return (
    <Transition appear show={visible} as={Fragment}>
      {screenType?.isTabletOrMobile ? (
        <Dialog as="div" open={visible} className="relative z-[90]" onClose={() => {}}>
          {children}
        </Dialog>
      ) : (
        <Dialog
          as="div"
          className="relative z-[90]"
          onClose={() => {
            screenType?.isTabletOrDesktop && setModal(false);
          }}>
          {children}
        </Dialog>
      )}
    </Transition>
  );
}

export default observer(AddGroupItemModal);
