import React, { Fragment, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import GroupOrderCart from 'components/GroupOrder/GroupOrderCart';
import PlaceOrderButton from 'components/GroupOrder/GroupOrderCart/PlaceOrderButton';
import { isPresent } from 'utils/helpers/array';
import { useRouter } from 'next/router';

function GroupOrderCartMobile({ restaurantDetail }) {
  const store = useContext(StoreContext);
  const groupOrderStore = toJS(store?.groupOrderStore);
  const { groupOrderCartMobile, orderDetails, cart } = groupOrderStore;
  const router = useRouter();
  const isAdmin = router?.query?.admin;

  return (
    <Transition appear show={groupOrderCartMobile} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed top-0 right-0 left-0 md:inset-0 bg-white">
          <div className="flex min-h-screen max-h-screen relative overflow-scroll md:min-h-full md:items-center md:justify-center md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="flex flex-col flex-1 w-full md:w-5/12 transform md:rounded-2xl bg-white py-5 px-2 text-left align-middle transition-all">
                <div className="flex justify-end px-4">
                  <button
                    type="button"
                    onClick={() => store?.groupOrderStore?.setGroupOrderCartMobile(false)}
                    className="btn-style">
                    <FontAwesomeIcon
                      style={{ width: 16, height: 26 }}
                      icon={faXmark}
                      size="2x"
                      color="#2D3958"
                    />
                  </button>
                </div>

                <GroupOrderCart groupOrder={orderDetails} restaurantDetail={restaurantDetail} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        {isPresent(cart?.items) && (
          <Transition.Child
            as={'div'}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="md:hidden">
              <div className="fixed bg-white w-full p-5 bottom-0 z-[90] justify-center items-center border-t-2 border-gray-200">
                <PlaceOrderButton isAdmin={isAdmin} />
              </div>
            </div>
          </Transition.Child>
        )}
      </Dialog>
    </Transition>
  );
}

export default observer(GroupOrderCartMobile);
