const LoggedInAvatar = ({ onClick, currentUser }) => (
  <button
    onClick={onClick}
    className="flex items-center justify-center bg-hh-accent-light w-[49px] h-[49px] rounded-[50%]">
    <span className="text-[21px] text-white font-inter-semibold">
      {currentUser?.attributes?.first_name?.length > 0 &&
        currentUser?.attributes?.first_name[0].toUpperCase()}
    </span>
  </button>
);

export default LoggedInAvatar;
