import React from 'react';
import { css } from '@emotion/react';
import { BeatLoader } from 'react-spinners';

const override = css`
  display: block;
  opacity: 1;
`;

function Loader() {
  return (
    <div className="flex justify-center items-center fixed inset-0" style={{ zIndex: 1000 }}>
      <BeatLoader color={'#DA2D8B'} loading={true} css={override} size={20} />
    </div>
  );
}

export default Loader;
