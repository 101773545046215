import { toJS } from 'mobx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { StoreContext } from 'pages/_app';
import { useContext, useEffect, useState } from 'react';

const FreshChatComponent = dynamic(() => import('react-freshchat'), {
  ssr: true,
});

const FreshChat = () => {
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const [isFreshChatLoaded, loadFreshChat] = useState(false);
  let { currentUser } = userStore;
  const router = useRouter();

  useEffect(() => {
    const timeout = null;

    timeout = setTimeout(() => {
      loadFreshChat(true);
    }, 4000);

    () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {isFreshChatLoaded && (
        <FreshChatComponent
          token={process.env.NEXT_PUBLIC_FRESH_CHAT_TOKEN}
          externalId={currentUser?.attributes?.email}
          firstName={currentUser?.attributes?.first_name}
          lastName={currentUser?.attributes?.last_name}
          email={currentUser?.attributes?.email}
          config={{
            headerProperty: {
              direction: 'rtr',
            },
          }}
          // tags={["loggedout"]}
        />
      )}
    </>
  );
};

export default FreshChat;
