import React, { Fragment, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import { RadioActive, RadioInActive, RemoveIcon } from 'assets/img';
import AddressInput from 'components/Shared/AddressInput';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import { priceFormatter } from 'utils/helpers/validation';
import { observer } from 'mobx-react';
import EmbedHTML from 'components/Shared/EmbedHTML';
import Cart from 'components/Cart';

function CartMobile({ restaurantDetail }) {
  const store = useContext(StoreContext);
  const cartStore = toJS(store?.cartStore);
  const { cartMobile } = cartStore;

  return (
    <Transition appear show={cartMobile} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => store?.cartStore?.setCartMobile(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed top-0 right-0 left-0 md:inset-0">
          <div className="flex min-h-screen max-h-screen overflow-scroll md:min-h-full md:items-center md:justify-center md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="flex flex-col flex-1 w-full md:w-5/12 transform md:rounded-2xl bg-white py-5 px-2 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-end px-4">
                  <button
                    type="button"
                    onClick={() => store?.cartStore?.setCartMobile(false)}
                    className="btn-style">
                    <FontAwesomeIcon
                      style={{ width: 16, height: 26 }}
                      icon={faXmark}
                      size="2x"
                      color="#2D3958"
                    />
                  </button>
                </div>

                <Cart
                  restaurantDetail={restaurantDetail}
                  setDeliveryPickupModal={value => store?.userStore?.setDeliveryPickupModal(value)}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default observer(CartMobile);
