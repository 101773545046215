import { observer } from 'mobx-react';
import Logo from 'components/Logo';

function LogoLayout() {
  return (
    <div
      className="flex py-3 md:items-center md:justify-between bg-white px-8 border-b-2 border-background mx-auto sticky top-0 md:static z-50"
      style={{ maxWidth: '1440px' }}>
      <>
        <div className="hidden sm:flex relative items-center justify-center w-full py-5 sm:py-0">
          <div className="hidden sm:flex flex-shrink-0 items-center btn-style">
            <Logo />
          </div>
        </div>

        <div className="flex sm:hidden w-full items-center justify-center">
          <div className="flex items-center btn-style">
            <Logo width={'106px'} height={'39px'} />
          </div>
        </div>
      </>
    </div>
  );
}

export default observer(LogoLayout);
