import { AppStoreBadge, LogoWhite, PlayStoreBadge } from 'assets/img';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

export default function Footer() {
  const router = useRouter();

  const firstSection = (
    <div className="flex flex-col md:w-3/12 md:mr-10">
      <Link href={'/'} className="">
        <button className="flex btn-style flex-shrink-0 items-center">
          <Image
            src={LogoWhite}
            alt="Logo-hungerhub"
            layout="fixed"
            width={'150px'}
            height={'55px'}
          />
        </button>
      </Link>

      <span className="md:mt-10 mt-4 font-inter-semibold text-md text-white">
        Download our Uncatering™ mobile app
      </span>

      <div className="flex mt-5">
        <div>
          <Link href="https://apps.apple.com/ca/app/uncatering-by-hungerhub/id1505624488">
            <a>
              <Image src={AppStoreBadge} alt="Get it on App Store" />
            </a>
          </Link>
        </div>

        <div className="ml-2 md:ml-3">
          <Link href="https://play.google.com/store/apps/details?id=com.uncatering.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <a>
              <Image src={PlayStoreBadge} alt="Get it on Play Store" />
            </a>
          </Link>
        </div>
      </div>
    </div>
  );

  const secondSection = (
    <div className="flex flex-col md:w-3/12">
      <span className="font-inter-semibold text-[20px] text-white mb-5">Useful Links</span>

      <a
        href="https://uncatering.hungerhub.com"
        className="font-inter-regular text-md text-white mb-3">
        Office Admin Login
      </a>

      <a
        href="https://restaurant.hungerhub.com"
        className="font-inter-regular text-md text-white mb-3">
        Restaurant Admin Login
      </a>

      <a
        href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/delivery-driver`}
        className="font-inter-regular text-md text-white mb-3">
        Delivery driver sign up
      </a>

      <a
        href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/restaurants-catering-sign-up`}
        className="font-inter-regular text-md text-white">
        Restaurant catering sign up
      </a>
    </div>
  );

  const thirdSection = (
    <div className="flex flex-col md:w-3/12">
      <span className="font-inter-semibold text-[20px] text-white mb-5">Learn</span>

      <a
        href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/about-hungerhub`}
        className="font-inter-regular text-md text-white mb-3">
        About us
      </a>

      <a
        href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/covid-19`}
        className="font-inter-regular text-md text-white mb-3">
        COVID-19 Response
      </a>

      <a
        href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/karibu`}
        className="font-inter-regular text-md text-white mb-3">
        Karibu Delivery as a Service
      </a>

      <a
        href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/bring-hungerhub-to-your-city`}
        className="font-inter-regular text-md text-white">
        Bring hungerhub to your city
      </a>
    </div>
  );

  const fourthSection = (
    <div className="flex flex-col md:w-3/12">
      <span className="font-inter-semibold text-[20px] text-white mb-5">Need Help?</span>

      <div className="flex flex-col flex-1 justify-between">
        <span className="font-inter-regular text-md text-white mb-3">
          Start live chat with one of our agents Weekdays : 9 AM-7 PM
        </span>

        <span className="flex font-inter-regular text-md text-white md:mb-1">
          180 John street Toronto ON M5T 1X5
        </span>
      </div>
    </div>
  );

  return (
    <div
      className={classNames({
        'flex flex-col w-full bg-hh-accent-medium px-7 md:px-20 pt-12 pb-10': true,
        'hidden sm:flex': router?.pathname?.includes('group-order/new'),
      })}>
      <div className="flex flex-col max-w-[1440px] md:mx-auto">
        <div className="hidden md:flex mb-12">
          {firstSection}

          {secondSection}

          {thirdSection}

          {fourthSection}
        </div>

        <div className="hidden sm:flex md:hidden flex-col mb-12">
          <div className="flex flex-wrap">
            <div className="w-1/2 pr-5">{firstSection}</div>

            <div className="w-1/2">{fourthSection}</div>
          </div>

          <div className="flex flex-wrap mt-10">
            <div className="w-1/2 pr-5">{secondSection}</div>

            <div className="w-1/2">{thirdSection}</div>
          </div>
        </div>

        <div className="flex sm:hidden md:hidden flex-col mb-8">
          <div className="mb-8">{firstSection}</div>

          <div className="mb-8"> {fourthSection}</div>

          <div className="mb-8"> {secondSection}</div>

          {thirdSection}
        </div>

        <hr className="opacity-20 mb-4" />

        <div className="flex flex-col md:flex-row mb-5">
          <div className="flex flex-col md:w-3/12 mr-3 md:mr-10 mb-2 md:mb-0">
            <span className="font-inter-semibold text-md text-white whitespace-nowrap">
              Made with ♥️ in Canada
            </span>
          </div>

          <div className="flex flex-col md:w-3/12"></div>

          <div className="flex flex-col md:flex-row w-full justify-between md:w-6/12">
            <a
              href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/legal/privacy`}
              className="flex text-sm font-inter-medium text-white mb-2 md:mb-0 py-2">
              Privacy Policy
            </a>

            <a
              href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/legal/terms-of-use`}
              className="flex text-sm font-inter-medium text-white mb-2 md:mb-0 py-2">
              Terms of use
            </a>

            <span className="flex text-sm font-inter-medium text-white py-2">
              Copyright hungerhub All Right reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
