import React, { Fragment, useContext, useEffect, useReducer } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

function ForgotPasswordModal({ visible, setModal }) {
  const screenType = Screen.ScreenType();
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  let { forgotModal } = userStore;

  const initialState = {
    email: '',
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  let { email } = provider;

  useEffect(() => {
    dispatch({ field: 'email', value: '' });
  }, [forgotModal]);

  const forgotPassword = e => {
    e.preventDefault();

    store.userStore?.setLoader(true);
    store.userStore
      .forgotPassword(email)
      .then(response => {
        store.userStore?.setLoader(false);

        if (response?.message && !response?.response) {
          store.userStore.setForgotModal(false);

          setTimeout(() => {
            store.userStore?.setErrorAlert(true, {
              title: response.message,
            });
          }, 300);
        }
      })
      .catch(() => store.userStore?.setLoader(false));
  };

  const submitButtons = (
    <div className="flex flex-col">
      <button type="submit" className="btn-purple mt-5 h-12">
        Confirm
      </button>
    </div>
  );

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          screenType?.isTabletOrDesktop && setModal(false);
        }}>
        <form onSubmit={forgotPassword}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed top-0 right-0 left-0 sm:inset-0 overflow-y-auto">
            <div className="flex min-h-screen sm:min-h-full sm:items-center sm:justify-center sm:p-4 md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="flex flex-col flex-1 md:flex-initial w-full md:w-5/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white py-10 px-8 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-end mb-3">
                    <button type="button" onClick={() => setModal(false)} className="btn-style">
                      <FontAwesomeIcon
                        style={{ width: 16, height: 26 }}
                        icon={faXmark}
                        size="2x"
                        color="#BBC0C5"
                      />
                    </button>
                  </div>

                  <div className="flex w-full justify-center items-center">
                    <Dialog.Title
                      as="h3"
                      style={{
                        fontSize: screenType?.isTabletOrMobile ? 23 : 32,
                      }}
                      className="font-inter-semibold leading-6 text-black-light">
                      <span className="text-[24px]">Forgot Password</span>
                    </Dialog.Title>
                  </div>

                  <div className="flex flex-col justify-center items-center flex-wrap mt-10 md:w-full">
                    <div className="flex flex-col w-full sm:w-2/3">
                      <input
                        required
                        type="email"
                        placeholder="Email*"
                        value={provider.email}
                        className="input-gray-bg-border text-md"
                        onChange={e => dispatch({ field: 'email', value: e.target.value })}
                      />

                      <div className="hidden sm:flex flex-col">{submitButtons}</div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>

          <Transition.Child
            as={'div'}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="sm:hidden">
              <div className="fixed bg-white w-full p-5 bottom-0 z-[90] justify-center items-center mt-10 border-t-2 border-gray-200">
                {submitButtons}
              </div>
            </div>
          </Transition.Child>
        </form>
      </Dialog>
    </Transition>
  );
}

export default observer(ForgotPasswordModal);
