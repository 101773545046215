export const isBrowser = () => {
  return typeof window !== 'undefined';
};

export const nextLocalStorage = () => {
  if (isBrowser()) {
    return window.localStorage;
  }
};
export const isLoggedIn = () => {
  const authToken = nextLocalStorage().getItem('auth_token');
  return authToken ? true : false;
};

export const setAccessToken = token => {
  nextLocalStorage()?.setItem('auth_token', token);
};

export const getAccessToken = () => {
  return nextLocalStorage()?.getItem('auth_token') || null;
};

export const removeKey = key => {
  nextLocalStorage().removeItem(key);
};

export const setKeyValue = (key, value) => {
  nextLocalStorage()?.setItem(key, value);
};
