import { observer } from 'mobx-react';
import { Tab } from '@headlessui/react';
import { classNames } from 'utils/helpers/array';

function DeliveryPickupTabs({ minWidth, tabClassName }) {
  return (
    <Tab.Group
      onChange={index => {
        console.log('Changed selected tab to:', index);
      }}>
      <Tab.List className="flex space-x-1 w-full h-12 rounded-xl bg-background p-1">
        <Tab
          key={'delivery'}
          styles={{ minWidth: minWidth }}
          className={({ selected }) =>
            classNames(
              'w-full rounded-lg py-2.5 text-md font-inter-medium leading-5 text-primary-black px-10',
              'ring-white outline-none focus:outline-none',
              selected ? 'bg-white shadow' : 'hover:text-primary-color',
              tabClassName
            )
          }>
          Delivery
        </Tab>

        <Tab
          key={'Pick-up'}
          disabled
          styles={{ minWidth: minWidth }}
          className={({ selected }) =>
            classNames(
              'flex items-center justify-center w-full rounded-lg  text-sm whitespace-nowrap font-inter-medium leading-5 text-border-gray px-10 text-md',
              'ring-white outline-none focus:outline-none',
              selected ? 'bg-white shadow' : '',

              tabClassName
            )
          }>
          <div className="flex flex-col">
            <span className="leading-3">Pick-up</span>
            <span className="text-[10px] leading-3 mt-1 font-inter-light">Coming soon</span>
          </div>
        </Tab>
      </Tab.List>
    </Tab.Group>
  );
}

export default observer(DeliveryPickupTabs);
