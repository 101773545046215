import Image from 'next/image';
import { Logo } from 'assets/img';
import { StoreContext } from 'pages/_app';
import { useEffect, useContext, useState, useRef } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import Link from 'next/link';
import moment from 'moment-timezone';
import { changeSpacesToHyphens } from 'utils/helpers/restaurants';
import { dateFormat } from 'utils/helpers/dateTime';

function CheckoutLayout() {
  const store = useContext(StoreContext);
  const cartStore = toJS(store?.cartStore);
  const userStore = toJS(store?.userStore);
  let { cart } = cartStore;
  const router = useRouter();
  let { address } = userStore;
  const moveToRestaurantDetails = () => {
    store.userStore.setLoader(true);

    let query = {
      date: moment(cart?.cartDate).format(dateFormat),
      time: cart?.cartTime,
      address,
    };

    if (router?.query?.utm_source == 'true') query.utm_source = router?.query?.utm_source;

    router.push({
      pathname: `/restaurant/${cart?.restaurant?.attributes?.code}/${changeSpacesToHyphens(
        cart?.restaurant?.attributes?.name
      )}`,
      query,
    });
  };

  return (
    <div
      className="flex py-5 items-center justify-between bg-background mx-auto px-8"
      style={{ maxWidth: '1440px' }}>
      {cart?.items?.length > 0 || router?.query?.shared ? (
        <div className="flex relative items-center justify-center w-full py-5 sm:py-0">
          <button
            onClick={moveToRestaurantDetails}
            className="flex btn-style items-center absolute left-0 w-full sm:w-[33%] md:w-[40%]">
            <div className="flex" style={{ width: 16, height: 16 }}>
              <FontAwesomeIcon icon={faAngleLeft} size="1x" className="icon" />
            </div>

            <span className="text-md text-left text-black-light ml-3">
              Go back to {cart?.restaurant?.attributes?.name} menu
            </span>
          </button>

          <Link href={'/'}>
            <button className="hidden sm:flex flex-shrink-0 items-center btn-style">
              <Image
                src={Logo}
                className="bg-background"
                alt="Logo-hungerhub"
                layout="fixed"
                width={'150px'}
                height={'55px'}
              />
            </button>
          </Link>
        </div>
      ) : (
        <>
          {store.userStore?.isHydrated && (
            <button className="text-md btn-style">
              <a href="/">Your cart is empty, Click here to add items in your cart to checkout</a>
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default observer(CheckoutLayout);
