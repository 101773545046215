import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { StoreContext } from 'pages/_app';
import { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getToBeExcludedDates } from 'utils/helpers/dateTime';
import { useRouter } from 'next/router';

const DPicker = ({
  selectedDate,
  dateFormat = 'MMMM d, yyyy',
  className,
  pickerClassName,
  minDate,
  activeRestaurant,
  availableDates,
  getAvailableDates,
  getAvailableTimes,
  fromFilters,
  fromModal,
  internalAddress,
  setSelectedDate,
  isGroupOrder,
  isCart,
  isCheckout,
  isModal,
  isInternalSelection,
}) => {
  const store = useContext(StoreContext);
  const [opened, setOpened] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const router = useRouter();

  return (
    <div
      className={classNames(className, { 'cursor-pointer': true })}
      onClick={() => setOpened(true)}>
      {!isCart ? (
        <DatePicker
          open={opened}
          forceShowMonthNavigation={true}
          includeDates={availableDates?.map(date => moment(date).toDate())}
          onMonthChange={date => {
            if (fromModal) {
              store?.userStore?.getAvailableDates({
                address: internalAddress,
                month: moment(date).month() + 1,
                year: moment(date).year(),
                current_date_time: moment().format('YYYY-MM-DD HH:mm'),
              });
            } else {
              getAvailableDates({
                month: moment(date).month() + 1,
                year: moment(date).year(),
              });
            }
          }}
          onCalendarOpen={() => {
            if (selectedDate)
              store?.userStore?.getAvailableDates({
                address: internalAddress,
                month: moment(selectedDate).month() + 1,
                year: moment(selectedDate).year(),
                current_date_time: moment().format('YYYY-MM-DD HH:mm'),
              });
          }}
          placeholderText="Select Date"
          selected={selectedDate ? new Date(moment(selectedDate)) : ''}
          dateFormat={dateFormat}
          onFocus={e => e.target.blur()}
          minDate={minDate}
          onClickOutside={() => setOpened(false)}
          onChange={date => {
            setTimeout(() => {
              setOpened(false);
            }, 20);

            if (fromModal) {
              store?.userStore?.getAvailableTimes({
                address: internalAddress,
                selected_date: moment(date).format('YYYY-MM-DD'),
                current_date_time: moment().format('YYYY-MM-DD HH:mm'),
              });
            } else if (!fromFilters)
              getAvailableTimes({
                selected_date: moment(date).format('YYYY-MM-DD'),
              });

            if (!fromModal) {
              router.replace(
                {
                  pathname: router.pathname,
                  query: {
                    ...router.query,
                    date: moment(date).format('YYYY-MM-DD'),
                  },
                },
                undefined,
                {
                  shallow: true,
                }
              );
            }

            store.userStore.setDate(date);
          }}
          className={`rounded-md pl-3 py-1 bg-background cursor-pointer text-primary-black text-left text-md focus:outline-none ${pickerClassName} ${
            pickerClassName ? '' : 'font-inter-medium'
          } text-md`}
        />
      ) : (
        <DatePicker
          open={opened}
          onMonthChange={date => setCurrentMonth(date)}
          excludeDates={getToBeExcludedDates(activeRestaurant, currentMonth || selectedDate)}
          placeholderText="Select Date"
          selected={
            selectedDate ? moment(selectedDate).isValid() && new Date(moment(selectedDate)) : ''
          }
          dateFormat={dateFormat}
          onFocus={e => e.target.blur()}
          minDate={minDate}
          onClickOutside={() => setOpened(false)}
          onChange={date => {
            setTimeout(() => {
              setOpened(false);
            }, 20);

            const formattedDate = moment(date).format('YYYY-MM-DD');

            if (isGroupOrder || isInternalSelection) setSelectedDate(formattedDate);
            else {
              store?.cartStore?.saveCartDetails({ cartDate: formattedDate });

              if (!isCheckout && !isModal)
                router.replace(
                  {
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      date: formattedDate,
                    },
                  },
                  undefined,
                  {
                    shallow: true,
                  }
                );
            }
          }}
          className={`rounded-md pl-3 py-1 bg-background cursor-pointer text-primary-black text-left text-md focus:outline-none ${pickerClassName} ${
            pickerClassName ? '' : 'font-inter-medium'
          } text-md`}
        />
      )}

      <div className="bg-background overflow-hidden rounded-md">
        <FontAwesomeIcon
          icon={faCalendar}
          color={'#2D3958'}
          size="1x"
          className="m-1.5 small-icon overflow-hidden"
        />
      </div>
    </div>
  );
};

export default observer(DPicker);
