import RootStore from 'store';
import { isPresent } from 'utils/helpers/array';
import { nextLocalStorage } from 'utils/helpers/localStorage';

export const alertHandler = response => {
  if (response?.data?.errors && response?.data?.errors[0] === 'User not authorized') {
    nextLocalStorage().clear();
  }

  let message = '';

  if (response?.status === 401) logout();
  else if (response?.status === 500)
    message = {
      title: 'Sorry!',
      body: 'We ran into an error, please refresh the page and try your request again. If you need any assistance please start a live chat or email us at help@hungerhub.com',
    };
  else
    message = response?.data
      ? {
          title: 'Error',
          body: response?.data?.message
            ? response?.data.message
            : parseErrors(
                response?.data?.errors
                  ? Array.isArray(response.data.errors)
                    ? response?.data?.errors?.filter(error => typeof error === 'object')?.length > 0
                      ? getUniqueEntries(response)
                      : `${response.data.errors.join(',')}`
                    : response?.data?.errors?.message || response?.data?.errors
                  : response.data?.errors?.message || response?.data || response.data.statusText
              ).join(' '),
        }
      : response;

  return message;
};

const getUniqueEntries = response => {
  let helperArray = [];

  response?.data?.errors?.map(error => {
    for (const [key, value] of Object.entries(error)) {
      helperArray = [...helperArray, `${key} ${value}`];
    }
  });

  let unique = helperArray.filter(function (item, pos) {
    return helperArray.indexOf(item) == pos;
  });

  return unique.join(', ');
};

export const logout = () => {
  // window.store.userStore.clearStoredData();
  // LocalStorage.removeKey("auth_token");
};

export const returnKeyValueError = (key, value) => {
  return `${key
    .replace(/\./g, ' ')
    .replace(/_/g, ' ')
    .replace(/(?: |\b)(\w)/g, key => {
      return key.toUpperCase();
    })} ${value}`;
};

export const parseErrors = list => {
  if (typeof list === 'string') {
    list = [list];
  } else if (typeof list === 'object') {
    let joinArray = [];

    list = Object.entries(list)?.map(([key, value]) => {
      return (
        isPresent(value) &&
        value?.map(err => {
          if (Array.isArray(err) && err?.filter(error => typeof error === 'object')?.length > 0) {
            let helperArray = [];
            err?.map(error => {
              for (const [key, value] of Object.entries(error)) {
                if (key !== 'id')
                  helperArray = [
                    ...helperArray,
                    `\n${key
                      .replace(/\./g, ' ')
                      .replace(/_/g, ' ')
                      .replace(/(?: |\b)(\w)/g, key => {
                        return key.toUpperCase();
                      })}: ${value}`,
                  ];
              }
            });

            let unique = helperArray.filter(function (item, pos) {
              return helperArray.indexOf(item) == pos;
            });

            return key === 'base'
              ? value
              : `${key
                  .replace(/\./g, ' ')
                  .replace(/_/g, ' ')
                  .replace(/(?: |\b)(\w)/g, key => {
                    return key.toUpperCase();
                  })} ${unique.join(' ')}\n\n`;
          } else if (typeof err == 'string') {
            console.log('err', `${key} ${err}\n`);
            return joinArray.concat(`${returnKeyValueError(key, err)}\n`);
          }
        })
      );
    });
  } else {
    if (list && list?.length > 0 && typeof list[0] === 'string') {
      list = list;
    } else {
      list = Object.entries(list)?.map(([key, value]) => {
        return (
          isPresent(value) &&
          value?.map(err => {
            return key === 'base' ? value : returnKeyValueError(key, err);
          })
        );
      });
    }
  }
  return list;
};
