import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { GrayTooltip, InfoIcon } from 'assets/img';

function TooltipNew({
  tooltipText,
  id,
  color,
  backgroundColor,
  textColor,
  place,
  whiteIcon,
  grayIcon,
}) {
  return (
    <>
      <div className="flex px-1 cursor-pointer" id={id}>
        {whiteIcon ? (
          <Image
            src={InfoIcon}
            width={16}
            height={16}
            alt={id}
            layout="fixed"
            data-class="my-tooltip"
            data-html={true}
          />
        ) : grayIcon ? (
          <Image
            src={GrayTooltip}
            style={{ width: 16, height: 16 }}
            data-class="my-tooltip"
            alt={id}
          />
        ) : (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            color={color ? color : '#000'}
            size="sm"
            style={{ width: 16, height: 16 }}
            className="small-icon"
            data-class="my-tooltip"
          />
        )}
      </div>

      <Tooltip
        id={id}
        className="tooltip w-auto text-left z-[1000]"
        anchorSelect={`#${id}`}
        place={place ? place : 'right'}
        html={tooltipText}
        style={{
          color: textColor ? textColor : '#fff',
          backgroundColor: backgroundColor ? backgroundColor : '#000',
        }}></Tooltip>
    </>
  );
}
export default TooltipNew;
