import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const NODE_ENV = process?.env?.NEXT_PUBLIC_APP_NODE_ENV;

export const initSentry = () => {
  if (NODE_ENV !== 'development' && NODE_ENV !== undefined)
    Sentry.init({
      dsn:
        SENTRY_DSN ||
        'https://7a3fcae1191a4fa9993f6aec5d6742df@o250277.ingest.sentry.io/4504615126499328',
      environment: NODE_ENV,
      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: 0.0,
      enabled: NODE_ENV !== 'development' && NODE_ENV !== undefined,
      ignoreErrors: [
        /ResizeObserver loop completed with undelivered notifications/,
        /Non-Error exception captured/,
        /Could not load 'places_impl'/,
      ],
    });
};
