import classNames from 'classnames';
import { useEffect, useRef } from 'react';

const { RemoveIcon, RemoveIconInactive } = require('assets/img');
const { default: Image } = require('next/image');
const { priceFormatter } = require('utils/helpers/validation');

const SubmitActions = ({
  edit,
  provider,
  dispatch,
  setModal,
  getItemTotalPrice,
  itemPrice,
  updateQuantity,
  setAttachLabelAlert,
  addToCart,
  isGroupOrder,
  isAdmin,
  menuItem,
  providerIndex,
  updatePrices,
  showEditSection = false,
}) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (isGroupOrder) updatePrices(menuItem, provider);
  }, [provider?.quantity]);

  return (
    <div
      className={classNames(
        'flex',
        { 'justify-between lg:mt-4 md:mt-4': edit },
        'flex-col sm:flex-row sm:justify-between sm:items-center sm:mt-16'
      )}>
      <div className="flex flex-col mb-3 sm:mb-0">
        <span className="text-sm mb-1">Total</span>

        <div className="flex">
          <span className="font-inter-semibold text-md text-header-text-2 mr-2">
            {priceFormatter(getItemTotalPrice)}
          </span>

          {provider?.quantity > 1 && !isGroupOrder && (
            <span className="font-inter-light text-md text-secondary-text">
              {priceFormatter(itemPrice)} <span className="text-sm">per item</span>
            </span>
          )}
        </div>
      </div>

      <div
        className={`${
          showEditSection ? 'mt-2 bg-white' : 'flex'
        } justify-between sm:justify-start`}>
        <div
          className="flex items-center border border-zinc-200 rounded-sm"
          style={{ paddingTop: 3, paddingBottom: 3 }}>
          <div
            className={classNames('py-1.5  px-3', {
              'mt-0.5': provider?.quantity == 1,
              'cursor-pointer': !(edit && provider?.quantity == 1),
            })}
            onClick={() => {
              provider?.quantity === 1
                ? !edit
                  ? setModal(false)
                  : null
                : dispatch({
                    field: 'quantity',
                    value: provider?.quantity - 1,
                    index: providerIndex,
                  });
            }}>
            {provider?.quantity > 1 ? (
              <div className="bg-black w-2 h-0.5" />
            ) : (
              <div>
                <Image
                  src={edit ? RemoveIconInactive : RemoveIcon}
                  className="icon bg-white"
                  layout="fixed"
                  alt="remove-icon"
                />
              </div>
            )}
          </div>

          <input
            className="text-black-light font-inter-medium text-md w-10 text-center outline-none"
            type="number"
            min="1"
            value={provider?.quantity}
            onChange={e => {
              if (provider.nameLabel !== '') setAttachLabelAlert(true);
              else updateQuantity(e, dispatch, providerIndex);
            }}
          />

          <div
            className="py-1.5 cursor-pointer px-3"
            onClick={() => {
              if (provider?.nameLabel !== '') setAttachLabelAlert(true);
              else
                dispatch({
                  field: 'quantity',
                  value: provider?.quantity + 1,
                  index: providerIndex,
                });
            }}>
            <span
              className="text-md text-black-light font-inter-medium"
              style={{ lineHeight: 0.8 }}>
              +
            </span>
          </div>
        </div>

        {!showEditSection && (
          <button
            className="btn-purple ml-2 w-28 text-md"
            style={{ borderRadius: 4 }}
            onClick={addToCart}>
            {edit ? 'Update' : 'Add'}
          </button>
        )}
      </div>
    </div>
  );
};

export default SubmitActions;
