import Image from 'next/image';
import { Logo } from 'assets/img';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import Link from 'next/link';

function GroupOrderCheckoutLayout() {
  const router = useRouter();
  const goBack = () => {
    router.back();
  };

  return (
    <div
      className="flex py-5 items-center justify-between bg-background mx-auto px-8"
      style={{ maxWidth: '1440px' }}>
      <div className="flex relative items-center justify-center w-full py-5 sm:py-0">
        <button
          onClick={goBack}
          className="flex btn-style items-center absolute left-0 w-full sm:w-[33%] md:w-[40%]">
          <div className="flex" style={{ width: 16, height: 16 }}>
            <FontAwesomeIcon icon={faAngleLeft} size="1x" className="icon" />
          </div>

          <span className="text-md text-left text-black-light ml-3">Go back to group order</span>
        </button>

        <Link href={'/'}>
          <button className="hidden sm:flex flex-shrink-0 items-center btn-style">
            <Image
              src={Logo}
              className="bg-background"
              alt="Logo-hungerhub"
              layout="fixed"
              width={'150px'}
              height={'55px'}
            />
          </button>
        </Link>
      </div>
    </div>
  );
}

export default observer(GroupOrderCheckoutLayout);
