import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { StoreContext } from 'pages/_app';
import { useContext, useRef } from 'react';
import Select from 'react-select';
import { getTimeOptionsWithDisabledInDirecory, timeFormat } from 'utils/helpers/dateTime';
import { useRouter } from 'next/router';
import { selectStyles, selectStylesDirectory } from 'utils/helpers/styles';
import { toJS } from 'mobx';

const CartTimePicker = ({
  onChange,
  selectedTime,
  addressTimezone,
  selectedDate,
  timeOptionsLoading,
  options,
  labelName,
  isGroupOrder,
}) => {
  const selectCartRef = useRef();

  const id = labelName ?? 'time-picker';

  return (
    <Select
      instanceId={id}
      aria-labelledby={id}
      aria-label={id}
      ref={selectCartRef}
      isLoading={timeOptionsLoading}
      isSearchable={false}
      styles={isGroupOrder ? selectStyles : selectStylesDirectory}
      isDisabled={!selectedDate}
      key={options?.length}
      placeholder="Time"
      closeMenuOnSelect
      value={
        options?.find(time => time?.value === selectedTime)
          ? options?.find(time => time?.value === selectedTime)
          : selectedTime
          ? {
              label: `${selectedTime} ${addressTimezone ?? ''}`,
              value: selectedTime,
            }
          : ''
      }
      isOptionDisabled={option => option.disabled}
      onChange={onChange}
      options={options}
    />
  );
};

const TPicker = ({
  selectedTime,
  selectedDate,
  isCart,
  selectPicker,
  fromModal,
  labelName,
  isGroupOrder,
  setSelectedTime,
  isInternalSelection,
}) => {
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  let { addressTimezone, allTimes, timeOptionsLoading } = userStore;
  const router = useRouter();

  const selectRef = useRef();
  const id = labelName ?? 'time-picker';

  const allOptions = getTimeOptionsWithDisabledInDirecory(
    selectedDate,
    allTimes?.map(time => ({
      label: `${moment(time, 'HH:mm').format(timeFormat)} ${addressTimezone ?? ''}`,
      value: moment(time, 'HH:mm').format(timeFormat),
    }))
  );

  const onMenuOpen = () => {
    setTimeout(() => {
      const { focusedOptionRef } = selectRef.current;
      focusedOptionRef && focusedOptionRef.scrollIntoView();
    }, 1);
  };

  const onChangeTime = time => {
    store.userStore.setTime(time?.value);

    if (!fromModal)
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            time: time?.value,
          },
        },
        undefined,
        {
          shallow: true,
        }
      );
  };

  const getCurrentValue = () =>
    allOptions?.find(time => time?.value === selectedTime)
      ? allOptions?.find(time => time?.value === selectedTime)
      : selectedTime
      ? {
          label: `${selectedTime} ${addressTimezone ?? ''}`,
          value: selectedTime,
        }
      : '';

  return (
    <>
      {!isCart ? (
        <Select
          aria-labelledby={labelName ?? 'time-picker'}
          aria-label={labelName ?? 'time-picker'}
          ref={selectRef}
          menuPosition={fromModal ? 'absolute' : 'fixed'}
          isLoading={timeOptionsLoading}
          onMenuOpen={onMenuOpen}
          isSearchable={false}
          styles={selectStylesDirectory}
          isDisabled={!selectedDate}
          placeholder="Time"
          closeMenuOnSelect
          value={getCurrentValue()}
          isOptionDisabled={option => option.disabled}
          onChange={onChangeTime}
          options={allOptions}
        />
      ) : (
        <CartTimePicker
          isGroupOrder={isGroupOrder}
          fromModal={fromModal}
          labelName={id}
          addressTimezone={addressTimezone}
          timeOptionsLoading={timeOptionsLoading}
          key={selectPicker?.options?.length + addressTimezone}
          options={selectPicker?.options ?? []}
          selectedTime={selectedTime ?? ''}
          selectedDate={selectedDate}
          onChange={time => {
            if (isGroupOrder || isInternalSelection) setSelectedTime(time?.value);
            else {
              store?.cartStore?.saveCartDetails({ cartTime: time?.value });

              router.replace(
                {
                  pathname: router.pathname,
                  query: {
                    ...router.query,
                    time: time?.value,
                  },
                },
                undefined,
                {
                  shallow: true,
                }
              );
            }
          }}
        />
      )}
    </>
  );
};
export default observer(TPicker);
