import { makeObservable, makeAutoObservable, observable, action } from 'mobx';
import api from '../client/api';
import qs from 'qs';
import { makePersistable } from 'mobx-persist-store';
import { client } from '../client';
import { toJS } from 'mobx';
import { nextLocalStorage } from '../utils/helpers/localStorage';

// import { alertHandler } from "utils/middlewares/alertHandler";

class marketManagementStore {
  markets = [];
  zones = [];

  constructor() {
    makePersistable(this, {
      name: 'marketManagementStore',
      properties: ['markets'],
      storage: nextLocalStorage(),
    });
    makeAutoObservable(this);
  }

  async getMarkets() {
    return client()
      .get(`${api.markets()}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          const filteredMarkets = data.data.filter(market => market.attributes.zones.length > 0);

          const filteredMarketsAndZones = filteredMarkets
            .map(market => {
              return {
                ...market,
                attributes: {
                  ...market.attributes,
                  zones: market.attributes.zones.filter(
                    zone => zone.attributes.display_on_odc == null || zone.attributes.display_on_odc
                  ),
                },
              };
            })
            .filter(market => market.attributes.zones.length > 0);

          this.markets = filteredMarketsAndZones;
          return data?.data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }
}

export default marketManagementStore;
