import React, { Fragment, useEffect, useMemo, useReducer, useState } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { Dialog, Transition, Disclosure } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import { priceFormatter } from 'utils/helpers/validation';
import { observer } from 'mobx-react';
import EmbedHTML from 'components/Shared/EmbedHTML';
import ConfirmationModal from 'components/ConfirmationModal';
import classNames from 'classnames';
import { useMenuItemSelection } from 'utils/hooks/useMenuItemSelection';
import CloseButton from 'components/CloseButton';
import SubmitActions from './SubmitActions';

function AddItemModal({
  visible,
  setModal,
  selectedItem,
  openedItems,
  addItemToCart,
  updateItem,
  edit,
  setImage,
  editItem,
}) {
  const screenType = Screen.ScreenType();
  const [attachLabelAlert, setAttachLabelAlert] = useState(null);
  const [getItemTotalPrice, setItemTotalPrice] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [optionTypesPrice, setOptionTypesPrice] = useState(0);
  let { setSelectedOptions, initRequiredCheck, updateQuantity } = useMenuItemSelection();
  let menuItem = edit
    ? editItem?.attributes?.menu_item?.attributes
    : openedItems?.[selectedItem]?.attributes;

  const initialState = {
    isInTime: true,
    optionTypes: {},
    instructions: '',
    nameLabel: '',
    quantity: 1,
    addItem: {
      options: [],
      instructions: '',
    },
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  let { quantity } = provider;

  useEffect(() => {
    dispatch({ type: 'reset' });

    if (!edit) initRequiredCheck(menuItem, dispatch);
    else setSelectedOptions(editItem, 'order_item_options', dispatch);
  }, [menuItem?.option_categories]);

  const getOptionTypeIDs = () => {
    dispatch({
      field: 'addItem',
      value: {
        ...provider.addItem,
        options: Object.values(provider.optionTypes).flat(),
      },
    });
  };

  useMemo(() => {
    let selectedOptionsSum = 0;

    selectedOptionsSum = Number(menuItem?.price);

    let totalItemPrice = parseFloat(
      parseFloat(optionTypesPrice) + (selectedOptionsSum || parseFloat(menuItem?.price))
    ).toFixed(2);

    setItemTotalPrice(totalItemPrice * (quantity == '' ? 1 : quantity));

    setItemPrice(totalItemPrice);
  }, [menuItem, optionTypesPrice, quantity]);

  useMemo(() => {
    const options = menuItem?.option_categories;
    getOptionTypeIDs();
    const selectedOptionsValues = Object.values(provider.optionTypes).flat();

    let price = 0;
    options?.forEach((e, index) => {
      e.attributes.options.forEach(option => {
        if (selectedOptionsValues.includes(option.id)) {
          price += parseFloat(option.attributes.price);
        }
      });
    });

    setOptionTypesPrice(price);
  }, [menuItem, provider?.optionTypes]);

  const radioCheck = (id, optionTypeId, type, required) => {
    let options = Object?.values(provider.optionTypes)?.flat();

    if (Object?.values(options)?.includes(id) && !required) {
      dispatch({
        field: 'optionTypes',
        value: Object.assign({}, provider.optionTypes, {
          [optionTypeId]: [],
        }),
      });
    } else {
      dispatch({
        field: 'optionTypes',
        value: Object.assign({}, provider.optionTypes, {
          [optionTypeId]: id,
        }),
      });
    }
  };

  const onCheckboxChange = (id, optionType, optionTypesArr, event) => {
    //prevent checkbox to uncheck if its required
    if (
      optionType?.attributes?.required &&
      optionTypesArr?.length === 1 &&
      !event?.target?.checked
    ) {
      event.preventDefault();
      return;
    }

    dispatch({
      field: 'optionTypes',
      value: Object.assign({}, provider.optionTypes, {
        [optionType.id]: event.target.checked
          ? optionTypesArr?.length > 0
            ? optionTypesArr?.concat(id)
            : [id]
          : optionTypesArr?.filter(option => option !== id),
      }),
    });
  };

  const addToCart = () => {
    let selectedOptions = [];
    Object.keys(provider.optionTypes).forEach(function (key) {
      let isArray = Array.isArray(provider.optionTypes[key]);
      selectedOptions = [
        ...selectedOptions,
        isArray
          ? provider.optionTypes[key]?.map(option_id => {
              return { option_id, option_group_id: key };
            })
          : { option_id: provider.optionTypes[key], option_group_id: key },
      ];
    });

    let payload = {
      menu_item_id: menuItem?.id,
      quantity: provider?.quantity,
      special_instructions: provider?.instructions,
      notes: provider?.nameLabel,
      menu_category_id: edit
        ? editItem?.attributes?.menu_category_id
        : openedItems?.[selectedItem]?.menuCategoryId,
      order_item_options_attributes: selectedOptions.flat(),
    };

    if (edit) updateItem(editItem?.id, payload);
    else addItemToCart(payload);

    setTimeout(() => {
      dispatch({ type: 'reset' });
      initRequiredCheck(menuItem, dispatch);
    }, 1000);
  };

  const onReduceQuantity = () => {
    dispatch({
      field: 'quantity',
      value: 1,
    });

    setAttachLabelAlert(null);
  };

  const submitActions = (
    <SubmitActions
      edit={edit}
      provider={provider}
      dispatch={dispatch}
      setModal={setModal}
      getItemTotalPrice={getItemTotalPrice}
      itemPrice={itemPrice}
      updateQuantity={updateQuantity}
      setAttachLabelAlert={setAttachLabelAlert}
      addToCart={addToCart}
      isGroupOrder={false}
    />
  );

  const children = (
    <>
      <Transition.Child
        // as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed top-0 right-0 left-0 sm:inset-0">
        <div className="flex min-h-screen max-h-screen md:min-h-full md:items-center md:justify-center md:p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Dialog.Panel className="flex flex-col max-h-screen overflow-auto flex-1 pb-44 md:pb-10 md:flex-initial w-full md:w-5/12 transform md:rounded-2xl bg-white py-10 px-8 text-left align-middle shadow-xl transition-all">
              <div className="hidden sm:flex sticky justify-between items-start mb-3">
                <div className="flex w-full">
                  <div className="flex items-stretch w-full">
                    {menuItem?.image_url && (
                      <div
                        className="flex rounded-xl overflow-hidden"
                        onClick={() => setImage(menuItem?.image_url)}>
                        <Image
                          src={menuItem?.image_url}
                          width={161}
                          height={188}
                          layout="fixed"
                          objectFit="cover"
                          alt="menu-item-image"
                        />
                      </div>
                    )}

                    <div
                      className={classNames({
                        'flex max-w-[60%] flex-col': true,
                        'ml-6': menuItem?.image_url,
                      })}>
                      <Dialog.Title
                        as="h3"
                        style={{
                          fontSize: screenType?.isTabletOrMobile ? 23 : 32,
                        }}
                        className="font-inter-semibold leading-6 text-black-light">
                        <EmbedHTML
                          text={menuItem?.display_name_with_html}
                          className={'text-black-light leading-9'}
                        />
                      </Dialog.Title>

                      <span className="text-md mt-3">
                        <EmbedHTML
                          text={menuItem?.description_with_html}
                          className={'text-black-light'}
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <CloseButton setModal={setModal} />
              </div>

              <div className="flex sm:hidden sticky justify-between items-center mb-3">
                <Dialog.Title
                  as="h3"
                  style={{ fontSize: screenType?.isTabletOrMobile ? 23 : 32 }}
                  className="font-inter-semibold leading-6 text-black-light">
                  <EmbedHTML
                    text={menuItem?.display_name_with_html}
                    className={'text-black-light leading-9'}
                  />
                </Dialog.Title>

                <CloseButton setModal={setModal} />
              </div>

              <span className="text-md sm:hidden">
                <EmbedHTML text={menuItem?.description_with_html} className={'text-black-light'} />
              </span>

              <div className="flex flex-col overflow-y-scroll pb-16 md:pb-0">
                <div className="flex sm:hidden">
                  {menuItem?.image_url && (
                    <div
                      className="flex rounded-xl overflow-hidden mt-5"
                      style={{ width: '100%', minHeight: 192, height: 192 }}
                      onClick={() => setImage(menuItem?.image_url)}>
                      <Image
                        src={menuItem?.image_url}
                        width={1500}
                        height={192}
                        objectFit="cover"
                        alt="menu-item-image"
                      />
                    </div>
                  )}
                </div>

                <div className="flex flex-col flex-wrap mt-8">
                  {menuItem?.option_categories?.map(optionType => (
                    <>
                      {optionType?.attributes?.choose_upto === 0 && (
                        <div key={optionType.id} className="flex flex-col mb-7">
                          <span className="uppercase text-md mb-1">
                            <EmbedHTML
                              isRow
                              className="uppercase text-md mb-1"
                              text={optionType?.attributes.display_name_with_html}
                              append={`(Unlimited) ${optionType.attributes.required ? '*' : ''}`}
                              appendClass="ml-1"
                            />
                          </span>

                          <div className="flex flex-col flex-wrap">
                            {optionType?.attributes?.options?.map(option => (
                              <label
                                key={option.id}
                                className="inline-flex items-center py-2 text-sm select-none mr-4">
                                <input
                                  autoComplete="off"
                                  type="checkbox"
                                  className="form-checkbox"
                                  disabled={option.attributes?.availability !== 'active'}
                                  value={option.id}
                                  checked={provider.optionTypes[optionType.id]?.includes(option.id)}
                                  onChange={e =>
                                    onCheckboxChange(
                                      option.id,
                                      optionType,
                                      provider?.optionTypes[optionType.id],
                                      e
                                    )
                                  }
                                />

                                <span className="ml-1 font-inter-regular text-md capitalize">
                                  <EmbedHTML
                                    isRow
                                    className="text-primary-black"
                                    text={option.attributes.display_name_with_html}
                                    append={
                                      option?.attributes?.availability === 'sold_out'
                                        ? 'Sold Out'
                                        : `+${priceFormatter(option.attributes.price)}`
                                    }
                                    appendClass={'text-primary-black text-md ml-1'}
                                  />
                                </span>
                              </label>
                            ))}
                          </div>
                        </div>
                      )}

                      {optionType?.attributes?.choose_upto >= 2 && (
                        <div key={optionType.id} className="flex flex-col mb-7">
                          <EmbedHTML
                            isRow
                            className="uppercase text-md mb-1"
                            text={optionType?.attributes.display_name_with_html}
                            append={`(choose up to ${optionType.attributes.choose_upto}${
                              optionType.attributes.required ? '*' : ''
                            })`}
                            appendClass="text-xs ml-1"
                          />

                          <div className="flex flex-col flex-wrap">
                            {optionType?.attributes?.options?.map(option => (
                              <label
                                key={option.id}
                                className="inline-flex items-center py-2 text-sm select-none mr-4">
                                <input
                                  autoComplete="off"
                                  type="checkbox"
                                  className="form-checkbox"
                                  value={option.id}
                                  checked={provider?.optionTypes[optionType.id]?.includes(
                                    option.id
                                  )}
                                  disabled={
                                    option.attributes?.availability !== 'active' ||
                                    (provider?.optionTypes[optionType.id]?.length >=
                                      optionType.attributes.choose_upto &&
                                      provider?.optionTypes[optionType.id]?.indexOf(option.id) ===
                                        -1)
                                  }
                                  onChange={e =>
                                    onCheckboxChange(
                                      option.id,
                                      optionType,
                                      provider?.optionTypes[optionType.id],
                                      e
                                    )
                                  }
                                />

                                <div className="ml-1 font-inter-regular text-md capitalize">
                                  <EmbedHTML
                                    isRow
                                    className="text-primary-black"
                                    text={option.attributes.display_name_with_html}
                                    append={
                                      option?.attributes?.availability === 'sold_out'
                                        ? 'Sold Out'
                                        : `+${priceFormatter(option.attributes.price)}`
                                    }
                                    appendClass={'text-primary-black text-md ml-1'}
                                  />
                                </div>
                              </label>
                            ))}
                          </div>
                        </div>
                      )}

                      {optionType?.attributes?.choose_upto === 1 && (
                        <div key={optionType.id} className="flex flex-col mb-7">
                          <span className="uppercase text-md mb-1">
                            <EmbedHTML
                              isRow
                              className="uppercase text-md mb-1"
                              text={optionType?.attributes.display_name_with_html}
                              append={`${optionType.attributes.required ? '*' : ''}`}
                              appendClass="ml-1"
                            />
                          </span>

                          <div className="flex flex-col flex-wrap">
                            {optionType?.attributes?.options?.map(option => (
                              <label
                                key={option.id}
                                className="inline-flex items-center py-2 text-sm select-none mr-4">
                                <input
                                  autoComplete="off"
                                  type="radio"
                                  className="form-checkbox"
                                  value={option.id}
                                  name={optionType.attributes.name}
                                  checked={provider.optionTypes[optionType.id] == option.id}
                                  disabled={option.attributes?.availability !== 'active'}
                                  onClick={e =>
                                    radioCheck(
                                      option.id,
                                      optionType.id,
                                      optionType.attributes.name,
                                      optionType.attributes.required
                                    )
                                  }
                                />
                                <span className="ml-1 font-inter-regular text-md capitalize">
                                  <EmbedHTML
                                    isRow
                                    className="text-primary-black"
                                    text={option.attributes.display_name_with_html}
                                    append={
                                      option?.attributes?.availability === 'sold_out'
                                        ? 'Sold Out'
                                        : `+${priceFormatter(option.attributes.price)}`
                                    }
                                    appendClass={'text-primary-black text-md ml-1'}
                                  />
                                </span>
                              </label>
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  ))}

                  <div className="flex flex-col mt-7">
                    <Disclosure defaultOpen={true}>
                      {({ open }) => (
                        /* Use the `open` state to conditionally change the direction of an icon. */
                        <>
                          <Disclosure.Button className="btn-style">
                            <div className="flex justify-between items-center bg-background px-5 py-[11px] mb-3">
                              <span className="text-md font-inter-light">Special Instructions</span>

                              <FontAwesomeIcon
                                size="1x"
                                icon={open ? faAngleUp : faAngleDown}
                                className="icon"
                              />
                            </div>
                          </Disclosure.Button>

                          <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel>
                              <div>
                                <input
                                  className="input-light text-md focus:outline-none focus:ring-0"
                                  placeholder="Ex. no onions"
                                  onChange={e =>
                                    dispatch({
                                      field: 'instructions',
                                      value: e.target.value,
                                    })
                                  }
                                  value={provider.instructions}
                                />

                                <div className="mt-2">
                                  <span className="text-xs font-inter-light">
                                    Special instructions are fulfilled at restaurants discretion and
                                    not guaranteed
                                  </span>
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </div>

                  <div className="flex flex-col mt-2">
                    <Disclosure defaultOpen={true}>
                      {({ open }) => (
                        /* Use the `open` state to conditionally change the direction of an icon. */
                        <>
                          <Disclosure.Button className="btn-style">
                            <div className="flex justify-between items-center bg-background px-5 py-[11px] mb-3">
                              <span className="text-md font-inter-light">
                                Attach a name to this item
                              </span>

                              <FontAwesomeIcon
                                size="1x"
                                className="icon"
                                icon={open ? faAngleUp : faAngleDown}
                              />
                            </div>
                          </Disclosure.Button>

                          <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel>
                              <div>
                                <input
                                  className="input-light text-md focus:outline-none focus:ring-0"
                                  placeholder="Ex. Jane Doe"
                                  onChange={e => {
                                    if (provider.quantity > 1) setAttachLabelAlert(true);
                                    else
                                      dispatch({
                                        field: 'nameLabel',
                                        value: e.target.value,
                                      });
                                  }}
                                  value={provider.nameLabel}
                                />

                                <div className="mt-2">
                                  <span className="text-xs font-inter-light">
                                    You can only add name to 1 cart item at a time
                                  </span>
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div className="hidden md:block">{submitActions}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>

      <Transition.Child
        as={'div'}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <div className="md:hidden">
          <div className="fixed bg-white w-full p-5 bottom-0 z-[90] justify-center items-center border-t-2 border-gray-200">
            {submitActions}
          </div>
        </div>
      </Transition.Child>

      {attachLabelAlert && (
        <ConfirmationModal
          visible={attachLabelAlert}
          setModal={setAttachLabelAlert}
          title={'Assign a name to item'}
          body={`To individualize this item and attach a name to it - the total number of ${menuItem?.title} items in your cart will be reduced to 1. Add as many items with names individually, or unnamed items in bulk to your cart.`}
          rightButtonText={'Reduce To 1'}
          onClickRightButton={onReduceQuantity}
        />
      )}
    </>
  );

  return (
    <Transition appear show={visible} as={Fragment}>
      {screenType?.isTabletOrMobile ? (
        <Dialog as="div" open={visible} className="relative z-[90]" onClose={() => {}}>
          {children}
        </Dialog>
      ) : (
        <Dialog
          as="div"
          className="relative z-[90]"
          onClose={() => {
            screenType?.isTabletOrDesktop && setModal(false);
          }}>
          {children}
        </Dialog>
      )}
    </Transition>
  );
}

export default observer(AddItemModal);
