import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const CloseButton = ({ setModal }) => (
  <button type="button" onClick={() => setModal(false)} className="btn-style">
    <FontAwesomeIcon style={{ width: 16, height: 26 }} icon={faXmark} size="2x" color="#BBC0C5" />
  </button>
);

export default CloseButton;
