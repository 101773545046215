import React, { Fragment, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import EmbedHTML from 'components/Shared/EmbedHTML';
import { enableScroll } from 'utils/helpers/styles';

function ErrorAlert() {
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);

  const { errorAlert, errorMessage } = userStore;

  const closeAlert = () => {
    enableScroll();
    store.userStore?.setErrorAlert(false, {});
  };

  return (
    <Transition appear show={errorAlert} as={Fragment}>
      <Dialog as="div" className="relative z-[90]" onClose={() => closeAlert()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="flex justify-end items-center">
                  <button type="button" onClick={() => closeAlert()} className="btn-style">
                    <FontAwesomeIcon
                      style={{ width: 16, height: 26 }}
                      icon={faXmark}
                      size="2x"
                      color="#BBC0C5"
                    />
                  </button>
                </div>

                <div className="flex flex-col justify-center items-center">
                  {errorMessage?.title && (
                    <EmbedHTML
                      text={errorMessage?.title}
                      className="text-center text-xl font-inter-medium text-black"
                    />
                  )}

                  {errorMessage?.body && (
                    <EmbedHTML
                      replaceText
                      text={errorMessage?.body}
                      className="text-center flex text-sm mt-5"
                    />
                  )}
                </div>

                <div className="flex justify-center items-center mt-10">
                  <div className="flex">
                    <button className="btn-purple py-3 px-5" onClick={() => closeAlert()}>
                      OK
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default observer(ErrorAlert);
