import Image from 'next/image';
import { CartBucket, Filter } from 'assets/img';
import { StoreContext } from 'pages/_app';
import { useEffect, useContext } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';
import { truncateText } from 'utils/helpers/string';
import { longDateFormat, timeFormat } from 'utils/helpers/dateTime';
import moment from 'moment-timezone';
import { isPresent } from 'utils/helpers/array';
import Logo from 'components/Logo';

function GroupOrderUserLayout() {
  const store = useContext(StoreContext);
  const cartStore = toJS(store?.cartStore);
  const groupOrderStore = toJS(store?.groupOrderStore);

  let { orderDetails, cart, adminOrdering } = groupOrderStore;
  const restaurantStore = toJS(store?.restaurantStore);
  const { activeMenuItemTagsLength } = restaurantStore;

  const router = useRouter();

  const moveToExpiryPage = () => {
    router?.push('/group-order/link-expired');
  };

  function isAdminUser() {
    const cookies = document.cookie.split('; ');
    console.log('cookies', cookies);
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'admin' && value === 'true') {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (!router?.isReady || !store?.userStore?.isHydrated) return;

    const isPastDeadline = moment(
      orderDetails?.attributes?.attendee_deadline,
      'YYYY-MM-DD hh:mm A'
    ).isBefore(moment());

    const isAdmin = router?.query?.admin && isAdminUser();

    if (
      !isAdmin &&
      orderDetails &&
      (orderDetails?.attributes?.attendee_deadline === null || isPastDeadline)
    ) {
      moveToExpiryPage();
    } else if (orderDetails && orderDetails?.attributes?.status !== 'active') {
      moveToExpiryPage();
    }
  }, [
    orderDetails?.attributes?.attendee_deadline,
    orderDetails?.attributes?.status,
    store?.userStore?.isHydrated,
    router?.isReady,
  ]);

  const adminInfo = (
    <div className="flex flex-col md:flex-row md:items-center bg-background rounded-xl px-6 py-3">
      <span className="text-[20px] font-inter-semibold capitalize">
        {truncateText(orderDetails?.attributes?.title, 32)}
      </span>

      <div className="flex mt-6 md:mt-0 md:ml-8">
        <div className="flex flex-col">
          <span className="text-md">Admin:</span>

          <span className="text-md font-inter-medium">{orderDetails?.attributes?.user_name}</span>
        </div>

        <div className="w-0.5 bg-divider mx-8" />

        <div className="flex flex-col">
          <span className="text-md">Address:</span>

          <span className="text-md font-inter-medium">
            {orderDetails?.attributes?.provincial_address}
          </span>
        </div>

        <div className="w-0.5 bg-divider mx-8" />

        <div className="flex flex-col">
          <span className="text-md">Delivery time:</span>

          <span className="text-md font-inter-medium">
            {moment(orderDetails?.attributes?.order_date).format(longDateFormat)},{' '}
            {moment(orderDetails?.attributes?.delivery_time, 'hh:mm A').format(timeFormat)}
          </span>
        </div>
      </div>
    </div>
  );

  const cartBucket = (
    <div
      onClick={() => store?.groupOrderStore?.setGroupOrderCartMobile(true)}
      className="flex items-center justify-center bg-background mr-2 px-5 py-2 rounded-lg h-12 m-w-[64px]">
      <Image src={CartBucket} width={16} height={16} layout="fixed" alt="cart-icon" />

      {isPresent(cart?.items) && (
        <span className="flex text-sm text-primary-black pl-2 font-inter-medium">
          {cart?.items?.length}
          <span className="hidden sm:flex ml-1">{pluralize('Item', cart?.items?.length)}</span>
        </span>
      )}
    </div>
  );

  return (
    <>
      <div
        className="flex sm:p-4 sm:py-7 md:items-center md:justify-between bg-white sm:px-8 border-b-2 border-background mx-auto sticky top-0 md:static z-50"
        style={{ maxWidth: '1440px' }}>
        <div className="hidden md:flex relative items-center w-full py-5 sm:py-0">
          <div className="flex md:w-2/12">
            <div className="hidden sm:flex flex-shrink-0 items-center btn-style">
              <Logo />
            </div>
          </div>

          <div className="flex w-full items-center md:w-10/12">{adminInfo}</div>
        </div>

        <div className="hidden sm:flex md:hidden flex-col w-full">
          <div className="flex items-center justify-between w-full mb-7 md:mb-0">
            <div className="hidden sm:flex flex-shrink-0 items-center btn-style">
              <Logo />
            </div>

            {cartBucket}
          </div>

          {adminInfo}
        </div>

        <div className="flex flex-col sm:hidden w-full">
          <div className="flex justify-between items-center w-full p-4">
            <div
              onClick={() => store?.restaurantStore?.setTagsFilterModal(true)}
              className="flex sm:hidden items-center justify-center bg-background mr-2 px-5 py-4 h-12 w-16 rounded-lg">
              <div>
                <Image src={Filter} width={16} height={16} layout="fixed" alt="filter-small" />
              </div>

              {activeMenuItemTagsLength > 0 && (
                <span className="ml-2 text-md">{activeMenuItemTagsLength}</span>
              )}
            </div>

            {cartBucket}
          </div>

          <div className="mt-4">{adminInfo}</div>
        </div>
      </div>
    </>
  );
}

export default observer(GroupOrderUserLayout);
